// Use this file to store common values like
// font size, z index, etc.

// Colors
$black: #000000;
$white: #ffffff;
$sail: #a2cdee;
$tangerine: #ff9900;
$torch: #ff1b21;

// Grays
$gray-10: #f4f4f4;
$gray-20: #e0e0e0;
$gray-30: #c6c6c6;
$gray-40: #a8a8a8;
$gray-50: #8d8d8d;
$gray-60: #666666;
$gray-70: #525252;
$gray-80: #393939;
$gray-90: #262626;
$gray-100: #161616;

// Global Variables
$transition-time: 0.425s; // Common transition time for hover states, etc.
$placeholder-color: $black;
$selection-color: $sail;
$primary-nav-breakpoint: large;
$below-primary-nav-breakpoint: medium down;

// Spacing
$mobile-component-spacing: 48px;
$mobile-sidebar-component-spacing: 48px;
$desktop-component-spacing: 72px;
$desktop-sidebar-component-spacing: 32px;

// Foundation overrides from standards
$global-width: 1712px; // 1920 viewport with 5% padding on either side
$breakpoints: (
	xsmall: 0,
	small: 400px,
	medium: 768px,
	large: 1024px,
	xlarge: 1280px,
	xxlarge: 1366px,
	xxxlarge: 1920px
);
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
	xsmall: 16px,
	medium: 16px,
);
$header-styles: (
	xsmall: (
		'h1': ('font-size': 48),
		'h2': ('font-size': 42),
		'h3': ('font-size': 38),
		'h4': ('font-size': 32),
		'h5': ('font-size': 28),
		'h6': ('font-size': 24),
	),
	large: (
		'h1': ('font-size': 72),
		'h2': ('font-size': 64),
		'h3': ('font-size': 56),
		'h4': ('font-size': 48),
		'h5': ('font-size': 40),
		'h6': ('font-size': 32),
	),
);
$breakpoint-classes: (xsmall small medium large);
