table {

	// Global table styles
	.gsc-control-cse & {
		table-layout: initial; // Shows GCSE tables correctly
	}

	.user-markup & {
		// WYSIWYG table styles

		tbody {

		}

		tr {

		}

		td {

		}

		th {

		}

		tbody,
		tr,
		th,
		td {

		}
	}
}

.table { // Wrapping tables in this will allow them to scroll horizontally if too wide
	position: relative;

	&__wrapper {
		max-width: 100%;
		overflow: hidden;
		overflow: auto;
	}

	table {
		min-width: 800px;
		width: 100%;

		thead {
			th {
				font-weight: bold;
				text-align: left;
				text-decoration: underline;
				white-space: nowrap;
			}
		}
	}

	&.sticky {
		@media screen {
			&:after {
				background: linear-gradient(270deg, $black 0%, rgba($black, 0) 100%);
				content: '';
				display: block;
				height: 100%;
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 0;
				width: 120px;
				z-index: 102;
			}
		}
	}
}
