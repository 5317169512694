.container {
	display: block;
	margin: 0 auto;
	max-width: 1366px;
	padding: 36px 20px;
	position: relative;
	width: 100%;
	z-index: 2;

	@include breakpoint(medium) {
		padding: 20px 8%;
	}

	@include breakpoint(large) {
		padding: 72px 8% 0;
	}

	@include breakpoint(xxlarge) {
		padding: 72px 108px 0;
	}
}
