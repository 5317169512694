.button {

	// Local Variables
	$this: &;
	border: 2px solid $white;

	// Styles
	border-radius: 5px;
	display: inline-block;
	font-size: 13.76px;
	font-weight: 500;
	line-height: 1.6;
	min-width: 186px;
	padding: 6px 12px;
	text-align: center;
	text-decoration: none;
	transition: 0.2s all ease;

	&--outline {
		&:link,
		&:visited {
			border-color: $white;
			color: $white;
		}

		&:hover {
			border-color: $tangerine;
			color: $tangerine;
		}

		&:active,
		&:focus-visible {
			border-color: $torch;
			color: $torch;
		}
	}

	&--reversed {
		&:link,
		&:visited {
			background: $black;
			border-color: $black;
			color: $white;
		}

		&:hover {
			background: $tangerine;
			border-color: $tangerine;
			color: $black;
		}

		&:active,
		&:focus-visible {
			background: $torch;
			border-color: $torch;
			color: $black;
		}
	}

	&--solid {
		&:link,
		&:visited {
			background: $white;
			border-color: $white;
			color: $black;
		}

		&:hover {
			background: $tangerine;
			border-color: $tangerine;
			color: $black;
		}

		&:active,
		&:focus-visible {
			background: $torch;
			border-color: $torch;
			color: $black;
		}
	}

	@include breakpoint(large) {
		border: 3px solid $white;
		font-size: 29.49px;
		min-width: 330px;
		padding: 12px 24px;
	}
}
