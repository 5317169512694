.content {

	// Local Variables
	$this: &;

	// Styles
	&__copy {
		p {
			line-height: 1.25;
			margin: 0 0 1em;

			@include breakpoint(large) {
				font-size: 27.04px;
				line-height: 1.3;
			}
		}

		br {
			display: none;

			@include breakpoint(medium) {
				display: block;
			}
		}
	}

	&--centered {
		#{$this}__copy {
			text-align: center;
		}
	}

	&--small {
		p {
			@include breakpoint(large) {
				font-size: 16px;
			}
		}
	}
}
