.masthead {
	background-image: url('/img/paper.jpg');
	background-position: bottom center;
	background-size: cover;
	margin: 0 auto;
	max-width: 1920px;
	width: 100%;

	&__inner {
		padding: 0 20px 25vw;
	}

	&__truck {
		display: block;
		height: auto;
		width: 100%;
	}

	&__text {
		&--sm {
			display: block;
			margin: 0 auto;
		}

		&--lg {
			display: none;
		}

		&.official-rules {
			height: 8.7vw;
			width: 70%;
		}

		&.trabaja-sin-llama {
			height: 25.9vw;
			width: 100%;
		}

		&.reglas-oficiales {
			height: 8.7vw;
			width: 80%;
		}

		&.work-flame-free {
			height: 28.7vw;
			width: 100%;
		}
	}

	@include breakpoint(medium) {
		&__inner {
			align-items: center;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			padding: 0 10% 13vw;
		}

		&__truck {
			width: 60%;
		}

		&__text {
			&--sm {
				display: none;
			}

			&--lg {
				display: block;
			}

			&.official-rules {
				height: 13.2vw;
				width: 37.5%;
			}

			&.trabaja-sin-llama {
				height: 17.3vw;
				width: 37.5%;
			}

			&.reglas-oficiales {
				height: 13.2vw;
				width: 37.5%;
			}

			&.work-flame-free {
				height: 12.3vw;
				width: 37.5%;
			}
		}
	}

	@include breakpoint(xxxlarge) {
		&__inner {
			padding: 0 192px 250px;
		}

		&__text {
			&.official-rules {
				height: 254px;
			}

			&.trabaja-sin-llama {
				height: 332px;
			}

			&.reglas-oficiales {
				height: 253px;
			}


			&.work-flame-free {
				height: 237px;
			}
		}
	}
}
