.dialog {

	// Local Variables
	$this: &;

	// Styles

	background: $white;
	border: none;
	padding: 20px;
	text-align: center;
	width: 75%;

	&__heading {
		color: $black;
		margin-top: 0;
	}

	&__copy {
		color: $black;
		font-size: 16px;
		line-height: 1.2;
		margin-bottom: 32px;

		> &:first-child {
			margin-top: 0;
		}

		> &:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(large) {
			font-size: 24px;
			margin-bottom: 30px;
		}

		@include breakpoint(xxxlarge) {
			font-size: 40px;
			margin-bottom: 40px;
		}
	}

	&::backdrop {
		background: rgba(0,0,0,0.6);
	}

	@include breakpoint(medium) {
		width: 50%;
	}

	@include breakpoint(large) {
		padding: 40px;
	}
}
