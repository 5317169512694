body {
	background: $black;
	color: $white;
	font-family: 'Helvetica Neue', Helvetia, Arial, sans-serif;
	overflow-x: hidden; // Prevents horizontal scroll bug
	width: 100vw;

	* {
		// Box Sixing
		box-sizing: border-box;

		// Allow non-breaking characters to wrap
		word-break: break-word;
		word-wrap: break-word;
	}
}
