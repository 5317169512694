.logo {
	// background-color: $white;
	border-top: 1px solid $gray-60;
	display: block;
	padding-top: 20px;
	text-align: center;

	img {
		height: 45px;
		width: 118px;
	}

	@include breakpoint(medium) {
		padding-top: 28px;
	}

	@include breakpoint(large) {
		border-top-width: 2px;
		padding-top: 48px;

		img {
			height: 81px;
			width: 209px;
		}
	}
}
