.diagram {

	// Local Variables
	$this: &;

	// Styles
	margin-top: 50px;
	position: relative;

	&__image {
		background: $black;
		border: 2px solid $white;
		border-radius: 50%;
		display: block;
		height: auto;
		margin: 0 auto;
		max-width: 362px;
		position: relative;
		width: 100%;
		z-index: 10;
	}

	&__item {
		margin-top: 32px;
		padding: 0 0 1rem 28px;
		position: relative;

		&:first-of-type {
			margin-top: 72px;

			&:before {
				background-image: url('/img/label-sm.png');
				background-position: bottom center;
				bottom: 100%;
				content: '';
				display: block;
				height: 81px;
				left: 50%;
				position: absolute;
				transform: translate(-50%, -30px);
				width: 10px;
			}
		}

		h2 {
			margin: 0 0 4px;
		}

		p {
			font-size: 14px;
			line-height: 1.6;
			margin: 0;
		}
	}

	@include breakpoint(medium) {
		display: grid;
		grid-gap: 400px 120px;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		margin-top: 90px;

		&__image {
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&__item {
			margin-top: 0;
			padding: 0;

			&:first-of-type:before,
			&:before {
				background-image: url('/img/label-md.png');
				background-position: bottom left;
				background-repeat: no-repeat;
				content: '';
				display: block;
				height: 200px;
				position: absolute;
				width: 24px;
			}

			&:first-of-type,
			&:nth-of-type(1) {
				grid-column: 2;
				grid-row: 1;
				margin-top: 0;

				&:before {
					left: auto;
					right: 100%;
					top: 0;
					transform: scale(-1) translate(4px, -8px);
				}
			}

			&:nth-of-type(2) {
				grid-column: 1;
				grid-row: 1;
				text-align: right;

				&:before {
					left: 100%;
					top: 0;
					transform: scaleY(-1) translate(4px, -8px);
				}
			}

			&:nth-of-type(3) {
				grid-column: 1;
				grid-row: 2;
				text-align: right;

				&:before {
					bottom: 100%;
					left: 100%;
					transform: translate(4px, 16px);
				}
			}

			&:nth-of-type(4) {
				grid-column: 2;
				grid-row: 2;

				&:before {
					bottom: 100%;
					right: 100%;
					transform: scaleX(-1) translate(4px, 16px);
				}
			}
		}
	}

	@include breakpoint(large) {
		grid-gap: 700px 160px;

		&__image {
			border-width: 3px;
			max-width: 642px;
		}

		&__item {
			&:first-of-type:before,
			&:before {
				background-image: url('/img/label-lg.png');
				height: 400px;
				width: 44px;
			}

			&:nth-of-type(1) {
				&:before {
					transform: scale(-1) translate(8px, -5px);
				}
			}

			&:nth-of-type(2) {
				&:before {
					transform: scaleY(-1) translate(8px, -5px);
				}
			}

			&:nth-of-type(3) {
				&:before {
					transform: translate(8px, 20px);
				}
			}

			&:nth-of-type(4) {
				&:before {
					transform: scaleX(-1) translate(8px, 20px);
				}
			}
		}
	}

	@include breakpoint(xlarge) {
		grid-gap: 700px 200px;

		&__item {
			p {
				font-size: 24.91px;
			}

			&:first-of-type:before,
			&:before {
				background-image: url('/img/label-lg.png');
				height: 400px;
				width: 44px;
			}

			&:nth-of-type(1) {
				&:before {
					transform: scale(-1) translate(8px, -12px);
				}
			}

			&:nth-of-type(2) {
				&:before {
					transform: scaleY(-1) translate(8px, -12px);
				}
			}

			&:nth-of-type(3) {
				&:before {
					transform: translate(8px, 28px);
				}
			}

			&:nth-of-type(4) {
				&:before {
					transform: scaleX(-1) translate(8px, 28px);
				}
			}
		}
	}

	// IE11 GRID HACKS
	@media all and (min-width: 768px) and (-ms-high-contrast: none) {
		// stylelint-disable-next-line
		display: -ms-grid;
		-ms-grid-columns: 1fr 120px 1fr;
		-ms-grid-rows: 1fr 400px 1fr;

		&__item {

			&:nth-of-type(1) {
				-ms-grid-column: 3;
				-ms-grid-row: 1;
			}

			&:nth-of-type(2) {
				-ms-grid-column: 1;
				-ms-grid-row: 1;
			}

			&:nth-of-type(3) {
				-ms-grid-column: 1;
				-ms-grid-row: 3;
			}

			&:nth-of-type(4) {
				-ms-grid-column: 3;
				-ms-grid-row: 3;
			}
		}
	}

	@media all and (min-width: 768px) and (-ms-high-contrast: none) {
		-ms-grid-columns: 1fr 160px 1fr;
		-ms-grid-rows: 1fr 700px 1fr;
	}

	@media all and (min-width: 768px) and (-ms-high-contrast: none) {
		-ms-grid-columns: 1fr 200px 1fr;
	}
}
