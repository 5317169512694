// Try modifying /helpers/overrides/_foundation.scss before setting global heading styles here

h1 {
	height: 0;
	overflow: hidden;
	position: absolute;
	width: 0;
}

h2 {
	font-size: 24px;
	font-weight: 700;
	letter-spacing: 0.025em;
	line-height: 1;

	@include breakpoint(xlarge) {
		font-size: 42.7px;
	}
}
