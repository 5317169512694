.global-footer {
	display: block;
	height: 320px;
	margin: 0 auto;
	max-width: 1920px;
	position: relative;
	width: 100%;
	z-index: 1;

	&__toggle {
		align-items: center;
		border: 2px solid $black;
		border-radius: 12px;
		bottom: 20px;
		color: $black;
		display: flex;
		flex-flow: column;
		font-size: 16px;
		height: 24px;
		justify-content: center;
		line-height: 20px;
		position: absolute;
		right: 20px;
		transition: 0.2s all ease;
		white-space: nowrap;
		width: 24px;
		z-index: 5;

		&:hover {
			background-color: $white;
		}

		&:active,
		&:focus-visible {
			background-color: $white;
			border-color: $torch;
			color: $torch;
		}

		.icon--play,
		.icon--pause {
			height: 12px;
			pointer-events: none;
			position: relative;
			width: 12px;
		}

		.icon--play {
			display: block;
			right: -1.5px;
		}

		.icon--pause {
			display: none;
		}

		&--is-playing {
			.icon--play {
				display: none;
			}

			.icon--pause {
				display: block;
			}
		}
	}

	&__video {
		bottom: 0%;
		height: auto;
		left: 50%;
		min-height: 100%;
		min-width: 100%;
		object-fit: cover;
		pointer-events: none;
		position: absolute;
		transform: translateX(-50%);
		width: auto;
	}

	@include breakpoint(large) {
		height: 38.1vw;
		min-height: 520px;

		&__toggle {
			bottom: 2vw;
		}
	}

	@include breakpoint(xxxlarge) {
		height: 732px;

		&__toggle {
			bottom: 40px;
		}
	}
}
