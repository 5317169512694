.language {

	// Local Variables
	$this: &;

	// Styles
	font-size: 13px;
	line-height: 1.5;
	margin: 0 auto;
	max-width: 1366px;
	padding: 2px 20px;
	text-align: right;
	text-transform: uppercase;
	width: 100%;

	@include breakpoint(medium) {
		padding-right: 18%;
	}

	@include breakpoint(large) {
		font-size: 17.08px;
		line-height: 2;
	}

	@include breakpoint(xxlarge) {
		padding-right: 248px;
	}
}
