%default-link {
	&:link,
	&:visited {
		color: $white;
	}

	&:hover {
		color: $tangerine;
	}

	&:active,
	&:focus-visible {
		color: $torch;
	}
}

a {
	text-decoration: underline;

	&:not([class]) {
		@extend %default-link !optional;
	}
}
