.ctas {
	align-items: center;
	display: flex;
	flex-flow: column;
	justify-content: center;
	margin-top: 48px;

	.button + .button {
		margin: 20px 0 0;
	}

	@include breakpoint(medium) {
		flex-flow: row nowrap;
		margin-top: 72px;

		.button + .button {
			margin: 0 0 0 25px;
		}
	}

	@include breakpoint(large) {
		margin-top: 96px;

		.button + .button {
			margin: 0 0 0 40px;
		}
	}
}
